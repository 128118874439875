

const ProductCard = ({ product, handlePageChange }) => {
  const handleClick = () => {
    // Function to handle the click event
    handlePageChange("ProductPage")
    // console.log("ProductCard.js: handleClick: product.id: ", product.id);
    // set in session storage the product id
    sessionStorage.setItem("productId", product.id);

  };

  return (
    <div className="product-card">
        <div
          className="card"
          onClick={handleClick}
          style={{ cursor: "pointer", boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", transition: "background-color 0.3s ease-in-out"}}
        >
          <div className="card-body">
            <span className="card-title">{product.name}</span>
          </div>
        </div>
    </div>  
  );
};

export { ProductCard };  
