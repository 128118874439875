import React from 'react';

import { ProductCard } from './ProductCard';

const ProductsList = ({ products, handlePageChange }) => {
  return (
    <div>
      <div className="row">
        {products.map((product, index) => (
          <div key={index} className="col-md-6 mb-4">
            <ProductCard product={product}  handlePageChange={handlePageChange}  />
          </div>
        ))}
      </div>
    </div>
  );
};


export { ProductsList };