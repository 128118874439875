import React from 'react';
import logo from './../logo_gai.png';
import './../App.css';

const Header = () => {
  return (
    <header className="bg-dark text-light p-3 d-flex align-items-center custom-header">
      <img src={logo} className="mr-3 rounded-circle" alt="logo" style={{width: "50px"}} />
      <h1 className="h4 m-0">Geoalertinfo AI</h1>
    </header>
  );
};

export default Header;
