import React from 'react';
import { Helmet } from 'react-helmet';


const Head = ({ pageTitle, pageDescription,  KeywordsSEO}) => (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={KeywordsSEO} />
      </Helmet>
    </div>
  );

export default Head;
