import React from "react";
import ProductDetail from "../components/ProductDetail";

// const ProductPage = ({ match }) => {
const ProductPage = () => {

  // Get session storage product id
  //const productId = parseInt(sessionStorage.getItem("productId"));
  //const productId = parseInt(match.params.id);
  //const product = products.find((p) => p.id === product.id);

  return <ProductDetail />;

};

export default ProductPage;
