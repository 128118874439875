import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Routes, Route } from "react-router-dom";
import Navigation from "../components/Navigation";
import ProductsListPage from "./ProductsListPage";
import ProductPage  from "./ProductPage";

const MainPage = () => {
  const [currentPage, setCurrentPage] = useState("ProductsListPage");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPage = () => {
    switch (currentPage) {
      case "ProductPage":
        return <ProductPage />;
      default:
        return <ProductsListPage handlePageChange={handlePageChange} />;
    }
  };
  
  return (
    <div className="container">
      <Navigation currentPage={currentPage} handlePageChange={handlePageChange} />
      <Router>
        <Routes>
          <Route exact path="/" component={ProductsListPage} />
          <Route path="/product/:id" component={ProductPage} />
        </Routes>
      </Router>
      <div>{renderPage()}</div>
    </div>
  );
};

export default MainPage;
