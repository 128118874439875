import React from "react";

import products from "../data/products";


const ProductDetail = () => {

    // Get session storage product id
    const productId = parseInt(sessionStorage.getItem("productId"));
    //const productId = parseInt(match.params.id);
    const product = products.find((p) => p.id === productId);

    return (
      <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h5>{product.name}</h5>
                <p>{product.description}</p>
            </div>
            <div className="d-flex vh-100">
                <iframe
                    src={product.url}
                    width="100%"
                    height="90%"
                    style={{ border: "0", borderRadius: "4px" }}
                />
  
            </div>
        </div>
      </div>
    );
  };
  
  export default ProductDetail;
