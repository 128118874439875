const products = [
    {
        id: 1,
        name: "Benchmarking(simulation)",
        description: "Analyse comparative de l'existant, qui vise à identifier les points forts et les points faibles des concurrents.",
        url: "https://ora.sh/embed/f5583b6d-c1dc-4096-bbbb-658258594cde",
    },
    {
        id: 2,
        name: "Brainstorming Silencieux(simulation)",
        description: "Séance de réflexion, à plusieurs, qui vise à trouver un maximum d'idées sur un sujet donné. Les participants écrivent leurs idées, qui sont ensuite regroupées et classées par thèmes.",
        url: "https://ora.sh/embed/5a369d31-42e9-4d2d-82cc-4bbb842b89af",
    },
    {
        id: 3,
        name: "Partage d'expérience(simulation)",
        description: "La découverte du vécu, le meilleur chemin vers la découverte de nouvelle pratiques auxquelles on n'aurait pas pensé. Le partage d'expérience est une méthode qui permet de faire émerger des idées nouvelles, de les confronter à la réalité et de les enrichir par l'expérience des autres.",
        url: "https://ora.ai/embed/a61e87ef-fd54-4819-b985-1c12214b1bbc",
    },
    {
        id: 4,
        name: "Evaluateur d'idée",
        description: "Bien évaluer les idées: une des clés du chemin vers l'innovation. Obtenir une note de 0 à 5 étoiles et une liste d'amélioration.",
        url: "https://ora.ai/embed/21b60671-d7b4-4ac3-8cc1-764233d4dd57",
    }
];


export default products;