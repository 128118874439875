import React from 'react';

const Navigation = ({currentPage, handlePageChange}) => {

  const changePage = (page) => {
    handlePageChange(page);
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">

      <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => changePage("ProductsListPage")}
        >
      <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <button
            className={`nav-link btn ${
              currentPage === "ProductsListPage" ? "active" : ""
            }`}
            onClick={() => changePage("ProductsListPage")}
            >
            Accueil
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
