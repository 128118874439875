import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-dark text-light p-3 footer" >
      <p>Tous droits réservés © Gai 2023</p>
    </footer>
  );
};

export default Footer;
