import React from "react";
import { ProductsList } from "../components/products/ProductsList";
import { Barcode } from "react-barcode";
import products from "../data/products";

const ProductsListPage = ({handlePageChange}) => {

  return (
    <main>
      <div className="container">
        <div className="row">

          <div className="col-md-8">
            <ul className="nav nav-tabs mb-3">

              <li className="nav-item">
                <button className="nav-link active">
                   Méthodologie
                </button>
              </li>

            </ul>
            <div className="row">
              <ProductsList  products={products}  handlePageChange={handlePageChange}  />
            </div>
          </div>

          <div className="col-md-4">
            <h5>Booster vos projets - Chatbots Spécifiques de la pensée design</h5>
            <p className="text-center text-muted lead">
                Outils d'aide à la résolution de problèmes centrés sur l'utilisateur, qui visent à comprendre les besoins et les désirs des utilisateurs pour suggérer des solutions innovantes et adaptées à leurs besoins.
                Ces outils effectuent vos tâches efficacement avec des réponses pertinentes dans un domaine précis.
            </p>
          </div>

        </div>
      </div>
    </main>
  );
};

export default ProductsListPage;
