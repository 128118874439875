import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from './components/Head';
import Header from './components/Header';
import Footer from './components/Footer';
import MainPage from './pages/MainPage';

function App() {
  return (
    <div className="App">
      <Head
        pageTitle="Booster vos projets avec l'IA" 
        pageDescription="Booster vos projets avec les chatbots spécifiques et assistants virtuels IA"
        KeywordsSEO="Chatbots Spécifiques, Assistants IA, Assistants virtuels IA, Chatbots, IA, Intelligence Artificielle, Booster vos projets, Booster vos projets avec l'IA, Boost, Pensée Design, Design Thinking, Outils de Résolution de Problèmes, Centré sur l'Utilisateur, Comprendre les Besoins des Utilisateurs, Solutions Innovantes, Tâches Efficaces, Réponses Pertinentes, Domaine Précis"
      />
      <Header />
      <MainPage />
      <Footer />
    </div>
  );
}

export default App;
